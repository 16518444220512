import React from "react";
import Select from "react-select";
import AirportListEn from "../../../../master-data/locale/en/airport-list-en.json";
import AirportSelectStyle from "../../../../master-data/booking-widget/airpot-select-style";
import Translation from "../../../../service/shared/multi-lingual/Translation";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class AirportSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuListOpen: false,
      isMenuListOpenArrival: false,
      isMenuListOpenDeparture:false
    };
    this.departureAirPortList = AirportListEn;
    this.customStylesSelect = null;
    if (this.props.isMobile) {
      this.customStylesSelect = AirportSelectStyle.mobileStyle;
    } else {
      this.customStylesSelect = AirportSelectStyle.desktopStyle;
    }
    this.arrivalAirPortList = undefined;
  }
  updateArrivalAirport = () => {
    if (this.props.itineraryPart[this.props.flightIndex].params.from !== null) {
      this.arrivalAirPortList = this.departureAirPortList.filter(
        (i) =>
          i.value !==
          this.props.itineraryPart[this.props.flightIndex].params.from.value
      );
    }
  };
  changeDepartureAirport(selectedAirport) {
    this.props.airportSelection(
      selectedAirport,
      "from",
      this.props.flightIndex
    );
    this.setState({isMenuListOpenDeparture:false});
    this.selectDeparture.blur();
    if (this.props.itineraryPart[this.props.flightIndex].params.to !== null) {
      if (
        selectedAirport.value ===
        this.props.itineraryPart[this.props.flightIndex].params.to.value
      ) {
        this.props.airportSelection(null, "to", this.props.flightIndex);
      }
    } 
    this.updateArrivalAirport();
    if (
      !this.props.isMobile &&
      this.props.itineraryPart[this.props.flightIndex].params.to === null
    ) {
      this.selectArrival.focus();
    }
  }
  changeArrivalAirport(selectedAirport) {
    this.props.airportSelection(selectedAirport, "to", this.props.flightIndex);
    this.selectArrival.blur();
    this.setState({ isMenuListOpenArrival: false });
  }
  onFocusDeparture = () => {
    this.setState({ isMenuListOpen: true,isMenuListOpenDeparture:true});
    this.props.changeValidation("fromFocus", this.props.flightIndex);
  };
  onBlurDeparture = () => {
    this.setState({ isMenuListOpen: false,isMenuListOpenDeparture:false });
    this.props.changeValidation("fromBlur", this.props.flightIndex);
  };
  onFocusArrival = () => {
    this.setState({ isMenuListOpen: true,isMenuListOpenArrival:true });
    this.props.changeValidation("toFocus", this.props.flightIndex);
  };
  onBlurArrival = () => {
    this.setState({ isMenuListOpen: false, isMenuListOpenArrival: false });
    this.props.changeValidation("toBlur", this.props.flightIndex);
  };
  componentDidUpdate(){
    this.updateArrivalAirport();
  }
  render() {
    return (
      <>
        <div className="col-sm-12  col-md-6 my-2 airport-selector">
         {this.props.labelMessage === null || this.props.labelMessage === undefined ? null :  <h5>{this.props.labelMessage}</h5>}
          {this.state.isMenuListOpen && this.props.isMobile ? (
            <div onClick={() => this.setState({ isMenuListOpen: false })}>
              <FontAwesomeIcon
                icon={faTimes}
                color="#cccccc"
                style={{
                  position: "fixed",
                  top: "10px",
                  right: "13px",
                  zIndex: 2000,
                  fontSize: "1.5em",
                }}
              />
            </div>
          ) : null}
          <Select
            className={
              this.props.validation[this.props.flightIndex].params.isFromValid
                ? null
                : "is-invalid-select"
            }
            id={"departure-airport-" + this.props.flightIndex}
            value={this.props.itineraryPart[this.props.flightIndex].params.from}
            styles={this.customStylesSelect}
            onFocus={this.onFocusDeparture}
            onBlur={this.onBlurDeparture}
            onChange={(selectedAirport) =>
              this.changeDepartureAirport(selectedAirport)
            }
            isSearchable={true}
            options={this.departureAirPortList}
            placeholder={
              this.props.validation[this.props.flightIndex].params
                .isFromValid ? (
                <span onClick={() => this.selectDeparture.focus()}>
                  <Translation Key="select_departure_airport" />
                </span>
              ) : (
                <span
                  style={{ color: "rgb(147 0 0)" }}
                  onClick={() => this.selectDeparture.focus()}
                >
                  <Translation Key="select_departure_airport_required" />
                </span>
              )
            }
            menuIsOpen={this.state.isMenuListOpenDeparture}
            ref={(node) => (this.selectDeparture = node)}
            noOptionsMessage={() => {
              return (
                <div>
                  <b>{<Translation Key="no_results_found" />}</b>
                  <br />
                  <span>{<Translation Key="no_results_found-msg" />}</span>
                </div>
              );
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6 my-2 airport-selector">
          <Select
            className={
              this.props.validation[this.props.flightIndex].params.isToValid
                ? null
                : "is-invalid-select"
            }
            id={"arrival-airport-" + this.props.flightIndex}
            value={this.props.itineraryPart[this.props.flightIndex].params.to}
            onFocus={this.onFocusArrival}
            onBlur={this.onBlurArrival}
            styles={this.customStylesSelect}
            onChange={(selectedAirport) =>
              this.changeArrivalAirport(selectedAirport)
            }
            isSearchable={true}
            options={this.arrivalAirPortList}
            placeholder={
              this.props.validation[this.props.flightIndex].params.isToValid ? (
                <span onClick={() => this.selectArrival.focus()}>
                  <Translation Key="select_arrival_airport" />
                </span>
              ) : (
                <span
                  style={{ color: "rgb(147 0 0)" }}
                  onClick={() => this.selectArrival.focus()}
                >
                  <Translation Key="select_arrival_airport_required" />
                </span>
              )
            }
            menuIsOpen={this.state.isMenuListOpenArrival}
            ref={(node) => (this.selectArrival = node)}
            // placeholder={this.props.itineraryPart[this.props.flightIndex].params.from===null?<Translation Key="select_departure_airport_first"/>:this.props.validation[this.props.flightIndex].params.isToValid?<Translation Key="select_arrival_airport"/>:<span style={{color:"rgb(147 0 0)"}}><Translation Key="select_arrival_airport_required"/></span>}
            noOptionsMessage={() => {
              return (
                <div>
                  <b>{<Translation Key="no_results_found" />}</b>
                  <br />
                  <span>{<Translation Key="no_results_found-msg" />}</span>
                </div>
              );
            }}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    itineraryPart: state.bookingWiget.itineraryPart,
    validation: state.bookingWiget.validation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    airportSelection: (value, part, flightIndex) =>
      dispatch({
        type: BookingWidgetAction.AIRPORTSELECTION,
        value: value,
        part: part,
        flightIndex: flightIndex,
      }),
    changeValidation: (domain, flightIndex) =>
      dispatch({
        type: BookingWidgetAction.VALIDATEWIDGET,
        domain: domain,
        flightIndex: flightIndex,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AirportSelection);
